export const axis = [
  {
    filter_uuid: '99e4a9e7-15fb-4c7c-9989-afad02151da4',
    filter_name: 'intern',
    type: 'axis_box_1'
  },
  {
    filter_uuid: '88772383-fee6-4e6c-8391-c0f153bdfabf',
    filter_name: 'extern',
    type: 'axis_box_1'
  },
  {
    filter_uuid: 'b77dd88c-72e9-40ee-929d-ba2afa760b8e',
    filter_name: 'risk',
    type: 'axis_box_2'
  },
  {
    filter_uuid: '8014750e-b8ad-421a-99a7-f842f6fabcee',
    filter_name: 'financial_impact',
    type: 'axis_box_2'
  },
  {
    filter_uuid: '8014750e-b8ad-421a-99a7-f842f6fabcee',
    filter_name: 'environmental_impact',
    type: 'axis_box_2'
  }
]


export const pointsColor = [

  {
    filter_name: 'default',
    color_panel: ['#e0ac2b', '#e85252', '#6689c6', '#9a6fb0', '#a53253']
  },
  {
    filter_name: 'probability',
    color_panel: ['#089C11', '#7CE182', '#FFEFA1', '#FF5F5F', '#C80000']

  },
  {
    filter_name: 'alignement',
    color_panel: ['#C80000', '#FF5F5F', '#FFEFA1', '#7CE182', '#089C11']

  }
]

export const pointsOrder = [

  {
    filter_name: 'default'
  },
  {
    filter_name: 'probability'
  },
  {
    filter_name: 'alignement'
  }
]

const global = {
  filter_uuid: 'fd4f13ea-1455-470e-94ca-c96c7c2e52c6',
  filter_name: 'global',
  type: 'axis_box_1'
}
